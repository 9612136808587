<template>
	<div v-bind:class="{'tall-chart':className,
	'taller-chart':legends ? legends.length > 32 : false,
	'taller-chart-2':legends ? legends.length > 48 : false,
	'taller-chart-3':legends ? legends.length > 64 : false,
	'taller-chart-4':legends ? legends.length > 90 : false,
	}" :style="{height:height,width:width}"/>
</template>

<style lang="scss">
.tall-chart {
	height: 430px !important;
}

.taller-chart {
	height: 680px !important;
}

.taller-chart-2 {
	height: 860px !important;
}

.taller-chart-3 {
	height: 1030px !important;
}

.taller-chart-4 {
	height: 1330px !important;
}
</style>

<script>
import echarts from 'echarts'

require('echarts/theme/macarons') // echarts theme
export default {
	props: {
		className: {
			type: String,
			default: 'chart'
		},
		width: {
			type: String,
			default: '100%'
		},
		height: {
			type: String,
			default: '300px'
		},
		titleString: {
			type: String,
			default: 'Registrations Distribution'
		},
		subTitleString: {
			type: String,
			default: 'Lorem'
		},
		dataSource: {
			type: Array,
			default: null
		},
		legends: {
			type: Array,
			default: null
		},
		dim: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			chart: null
		}
	},
	mounted() {
		setTimeout(() => {
			this.$nextTick(() => {
				this.initChart()
			})
		}, 1000)
	},
	beforeDestroy() {
		if (!this.chart) {
			return
		}
		this.chart.dispose()
		this.chart = null
	},
	methods: {
		initChart() {
			this.chart = echarts.init(this.$el, 'macarons')
			this.chart.setOption({
				title: {
					text: this.titleString || '',
					subtext: this.subTitleString || '',
					left: 'center'
				},
				tooltip: {
					trigger: 'axis',
					formatter: '{a} <br/>{b} : {c} ({d}%)'
				},
				legend: {
					left: 'center',
					bottom: '10',
					data: this.legends
				},
				grid: {
					// left: '-13%',
					// right: '4%',
					// bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					// boundaryGap: false,
					data: this.legends//['周一', '周二', '周三', '周四', '周五', '周六', '周日']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: this.titleString,
						type: 'bar',
						stack: '总量',
						data: this.dataSource,
						animationEasing: 'cubicInOut',
						animationDuration: 2600,
						avoidLabelOverlap: true,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.2)'
							}
						}
					},

				]
			})
		}
	}
}
</script>
