<template lang="pug">
	.updates-badge-conteiner
		.updates-badge()
			el-tooltip(:content="showBadge ? `Show new visits and leads since ${polling.since}` : 'Nothing to show'")
				el-badge(:value="`V:${polling.visits}, L:${polling.leads}`" class="item")
					font-awesome-icon.show-meta-data(size="lg", :icon="['fa', 'clock']" @click="togglePopup")
		el-dialog.override-width.search-dialog(title="Updates statistics" :visible.sync="showPopup" width="70%")
			el-row.no-break
				el-col(:span="24")
					p Show new visits and leads since <u><b>{{polling.since}}</b></u>. These statistics will appear until you close this popup. Charts show the correlation of leads and visits from the last time the badge was clicked. Ability to view data since specific date will be available in the near future
			el-row.mt-3(v-if="showPopup")
				el-col(:span="12")
					h5 Visits
					pieChart(v-if="showChart && pieData.length > 0" , :data-source="selectedFunnel !== '' ? funnelPieData : pieData" :titleString="'Visits Distribution'" :subTitleString="'By Steps'")
					el-select(placeholder="Select Funnel" size="mini" v-model="selectedFunnel" @change="selectFunnel")
						el-option(label="Show Total" value="")
						el-option(v-for="o of visitsList" :label="`${o.label}`" :value="`${o.name}`")
				el-col(:span="12")
					h5 Leads
					line-chart(v-if="showBarChart && leadsLegends[currentType]" , :legends="leadsLegends[currentType]" :data-source="lineData[currentType]" :titleString="'Leads Distribution'" :subTitleString="`By ${currentType}`")
					el-select(placeholder="Select Funnel" size="mini" v-model="currentType" @change="selectChartType")
						el-option(v-for="o of ['brand','funnel','spreadsheet','source_app']" :label="`${o}`" :value="`${o}`")
</template>


<style lang="scss" scoped>
@media only screen and (max-width: 1170px) {
	.updates-badge {
		display: none;
	}
}

.no-break {
	word-break: normal;
}

.show-meta-data {
	margin-left: 0.5rem;
	color: #98c5cf;
	&:hover {
		color: white;
		cursor: pointer;

	}
}
</style>

<script>
import pieChart from '../components/pie';
import lineChart from '../components/line';
export default {
	name: 'updates-popup',
	components: { pieChart, lineChart },
	data() {
		return {
			fetchWatcher: null,
			showPopup: false,
			showBadge: false,
			showChart: true,
			showBarChart: true,
			polling: {
				visits: 0,
				leads: 0,
				since: null
			},
			currentType: 'brand',
			leadsList: [],
			leadsLegends: [],
			visitsList: [],
			pieData: [],
			lineData: [],
			funnelPieData: [],
			funnelPieHash: {},
			selectedFunnel: '',
			selectedChartType: ''
		}
	},
	created() {
		this.getPoll()
	},
	mounted() {
		this.fetchWatcher = setInterval(() => {
			this.getPoll();
		}, 20000)
	},
	beforeDestroy() {
		clearInterval(this.fetchWatcher)
	},
	methods: {
		getPoll() {
			this.$apix.sendHttpRequest('GET', 'me/poll')
					.then(res => {
						this.polling.visits = res.visits;
						this.polling.leads = res.leads;
						this.polling.since = res.since;
						this.showBadge = !!res.since;
					})
		},
		togglePopup() {
			this.pieData = [];
			this.funnels = [];
			this.selectedFunnel = '';

			if(!this.showBadge) return;
			this.showPopup = !this.showPopup;
			this.$apix.sendHttpRequest('GET', 'me/meta')
				.then(res => {
					// Visits
					res.visits.by_funnel.forEach(e => {
						e.name = `${e.funnel_name}|${e.funnel_title}|${e.brand_name}`
						e.label = `${e.funnel_name} (${e.funnel_title}), ${e.brand_name}`
						this.visitsList.push(e);
						this.funnelPieHash[e.name] = [
							{ name: 'Pre', value: +e.step_pre },
							{ name: 'Step 1', value: +e.step_1 },
							{ name: 'Step 2', value: +e.step_2 }
						]
					});
					this.pieData=[
						{ name: 'Pre', value: res.visits.total.step_pre },
						{ name: 'Step 1', value: res.visits.total.step_1 },
						{ name: 'Step 2', value: res.visits.total.step_2 }
					]

					// leads
					const hash = { brand: [], funnel: [], spreadsheet: [], source_app: [] };
					res.leads.forEach(e => {
						if(e.brand_name && !hash.brand[e.brand_name]) hash.brand[e.brand_name] = 0;
						if(e.brand_name) hash.brand[e.brand_name] += e.brand_name_count;

						if(e.funnel_name && !hash.funnel[e.funnel_name]) hash.funnel[e.funnel_name] = 0;
						if(e.funnel_name) hash.funnel[e.funnel_name] += e.funnel_name_count;

						if(e.spreadsheet_hash && !hash.spreadsheet[e.spreadsheet_hash]) hash.spreadsheet[e.spreadsheet_hash] = 0;
						if(e.spreadsheet_hash) hash.spreadsheet[e.spreadsheet_hash] += e.spreadsheet_hash_count;

						if(e.source_app && !hash.source_app[e.source_app]) hash.source_app[e.source_app] = 0;
						if(e.source_app) hash.source_app[e.source_app] += e.source_app_count;
					});
					this.lineData.brand = (Object.entries(hash.brand).map(e => Object.assign({}, { name: e[0], value: e[1] })))
					this.lineData.funnel = (Object.entries(hash.funnel).map(e => Object.assign({}, { name: e[0], value: e[1] })))
					this.lineData.spreadsheet = (Object.entries(hash.spreadsheet).map(e => Object.assign({}, { name: e[0], value: e[1] })))
					this.lineData.source_app = (Object.entries(hash.source_app).map(e => Object.assign({}, { name: e[0], value: e[1] })))

					this.leadsLegends.brand = Object.keys(hash.brand)
					this.leadsLegends.funnel = Object.keys(hash.funnel)
					this.leadsLegends.spreadsheet = Object.keys(hash.spreadsheet)
					this.leadsLegends.source_app = Object.keys(hash.source_app)

					this.lineData.source_app = (Object.entries(hash.source_app).map(e => Object.assign({}, { name: e[0], value: e[1] })))
				})
			setTimeout(() => {
				this.$apix.sendHttpRequest('PUT', 'me/renew-poll', {}).then(() => this.getPoll());
			}, 5000)
		},
		selectFunnel() {
			this.showChart = false;
			this.funnelPieData = this.funnelPieHash[this.selectedFunnel]
			setTimeout(() => {
				this.showChart = true;
			}, 0)
		},
		selectChartType() {
			this.showBarChart = false
			setTimeout(() => {
				this.showBarChart = true
			}, 0)
		}
	}
}
</script>
