import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex);
const apv = (require('../package.json')).version;

function constructor(data) {
    return new Vuex.Store({
        state: {
            LOGGED_IN: !!localStorage.getItem('login') && !!localStorage.getItem('auth') || null,
            LOGGED_USER: localStorage.getItem('user') || null,
            CUR_SRV_TIME: Date.now(),
            CUR_SRV_TIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,

            // AUTH_TOKEN: Cookies.get('cb') || null,
            AUTH_TOKEN: localStorage.getItem('auth') || null,
            ROLE_ID: localStorage.getItem('role_id'),
            CMU: localStorage.getItem('cmu'),
            CSL: localStorage.getItem('csl'),
            CVD: localStorage.getItem('cvd'),
            CCL: localStorage.getItem('ccl'),
            CRL: localStorage.getItem('crl'),
            CETS: localStorage.getItem('cets'),
            CED: localStorage.getItem('ced'),
            CTF: localStorage.getItem('ctf'),
            CCM: localStorage.getItem('ccm'),
            CVS: localStorage.getItem('cvs'),
            CCSC: localStorage.getItem('ccsc'),
            CFTD: localStorage.getItem('cftd'),
            CDEX: localStorage.getItem('cdex'),
            ACL: localStorage.getItem('acl'),
            CENDE: localStorage.getItem('cende'),
            CSBO: localStorage.getItem('csbo'),
            CSPA: localStorage.getItem('cspa'),
            SOL: localStorage.getItem('sol'),

            // integration
            CUR_BRAND: Cookies.get('cb') || null,
            CUR_BRAND_NAME: Cookies.get('cbn') || null,
            CUR_INTEGRATION_TAB: Cookies.get('cit') || 'first',
            
            // Settings
            CUR_JOB_TYPE: Cookies.get('cjty') || 'INTEGRATION',
            CUR_SETTINGS_TAB: Cookies.get('cst') || 'dyn_params',

            // Filters
            CUR_JOB_FILTERS: localStorage.getItem('fltjob') ? tryParseObject(localStorage.getItem('fltjob')) : { },
            CUR_SEARCH_FILTERS: localStorage.getItem('fltsrch') ? tryParseObject(localStorage.getItem('fltsrch')) : { },
            LOCK_SEARCH_FILTERS: Cookies.get('lcksrchflt') || '-1',

            // Search table
            SELECTED_COLUMNS_MAIN_TBL: localStorage.getItem('cols') ? tryParseObject(localStorage.getItem('cols')) : { },
            PACKAGE_VERSION: localStorage.getItem('apv'),
            ORIGINAL_PACKAGE_VERSION: apv,
            LAST_UPDATE_ME: localStorage.getItem('lum'),
            ALLOWED_COLS: localStorage.getItem('cols') ? tryParseObject(localStorage.getItem('cols')) : [],
            AUTO_REFRESH_LEADS: Cookies.get('arl') || 'FUNNEL',

            // Integrations tab
            ITG_SELECTED_FUNNELS_LIST: []  // integration checkbox funnels list
        },
        getters: {
            LOGGED_IN: (state) =>  state.LOGGED_IN,
            LOGGED_USER: (state) => typeof state.LOGGED_USER === 'object' ? state.LOGGED_USER : JSON.parse(state.LOGGED_USER),
            CUR_SRV_TIME: (state) => state.CUR_SRV_TIME,
            CUR_SRV_TIMEZONE: (state) => state.CUR_SRV_TIMEZONE,
            AUTH_TOKEN: (state) => state.AUTH_TOKEN,
            ROLE_ID: (state) => state.ROLE_ID,
            CMU: (state) => state.CMU,
            CSL: (state) => state.CSL,
            CVD: (state) => state.CVD,
            CCL: (state) => state.CCL,
            CRL: (state) => state.CRL,
            CETS: (state) =>state.CETS,
            CED: (state) => state.CED,
            CTF: (state) => state.CTF,
            CCM: (state) => state.CCM,
            CVS: (state) => state.CVS,
            CCSC: (state) => state.CCSC,
            CFTD: (state) => state.CFTD,
            CDEX: (state) => state.CDEX,
            ACL: (state) => parseAcl(state.ACL),
            CENDE: (state) => state.CENDE,
            CSBO: (state) => state.CSBO,
            CSPA: (state) => state.CSPA,
            SOL: (state) => state.SOL,

            // integration
            CUR_BRAND_GETTER: (state) => state.CUR_BRAND,
            CUR_BRAND_NAME_GETTER: (state) => state.CUR_BRAND_NAME,
            CUR_INTEGRATION_TAB: (state) => state.CUR_INTEGRATION_TAB,
            CUR_JOB_TYPE: (state) => state.CUR_JOB_TYPE,
            CUR_SETTINGS_TAB: (state) => state.CUR_SETTINGS_TAB,

            // Filters
            CUR_JOB_FILTERS: (state) => state.CUR_JOB_FILTERS,
            CUR_SEARCH_FILTERS: (state) => state.CUR_SEARCH_FILTERS,
            LOCK_SEARCH_FILTERS: (state) => state.LOCK_SEARCH_FILTERS,

            // Leads / Customers selected columns
            SELECTED_COLUMNS_MAIN_TBL: state => state.SELECTED_COLUMNS_MAIN_TBL,
            PACKAGE_VERSION: (state) => state.PACKAGE_VERSION,
            ORIGINAL_PACKAGE_VERSION: (state) => state.ORIGINAL_PACKAGE_VERSION,
            LAST_UPDATE_ME: (state) => state.LAST_UPDATE_ME,
            AUTO_REFRESH_LEADS: (state) => state.AUTO_REFRESH_LEADS,

            // Integrations tab
            ITG_SELECTED_FUNNELS_LIST: state => state.ITG_SELECTED_FUNNELS_LIST,
            ITG_SELECTED_FUNNELS_LIST_UUID: state => state.ITG_SELECTED_FUNNELS_LIST.map(e => e.uuid),
            ITG_SELECTED_FUNNELS_FLAG: state => Array.isArray(state.ITG_SELECTED_FUNNELS_LIST) && state.ITG_SELECTED_FUNNELS_LIST.length > 0
        },
        mutations: {
            SET_LOGGED_IN: (state, newValue) => state.LOGGED_IN = newValue,
            SET_LOGGED_USER: (state, newValue) => {
                state.LOGGED_USER = {...state.LOGGED_USER, ...newValue}
            },
            SET_CUR_SRV_TIME: (state, newValue) => state.CUR_SRV_TIME = newValue,
            SET_CUR_SRV_TIMEZONE: (state, newValue) => state.CUR_SRV_TIMEZONE = newValue,
            SET_AUTH_TOKEN: (state, newValue) => state.AUTH_TOKEN = newValue,
            SET_ROLE_ID: (state, newValue) =>  state.ROLE_ID = newValue,
            SET_CUR_BRAND: (state, brand) => {
                state.CUR_BRAND = brand.id;
                state.CUR_BRAND_NAME = brand.name;
            },
            SET_CUR_INTEGRATION_TAB: (state, tab) => state.CUR_INTEGRATION_TAB = tab,
            SET_CMU: (state, cmu) => state.CMU = cmu,
            SET_CSL: (state, csl) => state.CSL = csl,
            SET_CVD: (state, cvd) => state.CVD = cvd,
            SET_CCL: (state, ccl) => state.CCL = ccl,
            SET_CRL: (state, crl) => state.CRL = crl,
            SET_CETS: (state, cets) => state.CETS = cets,
            SET_CED: (state, ced) => state.CED = ced,
            SET_CTF: (state, ctf) => state.CETS = ctf,
            SET_CCM: (state, ccm) => state.CCM = ccm,
            SET_CVS: (state, cvs) => state.CVS = cvs,
            SET_CCSC: (state, ccsc) => state.CCSC = ccsc,
            SET_CFTD: (state, cftd) => state.CFTD = cftd,
            SET_CDEX: (state, cdex) => state.CDEX = cdex,
            SET_ACL: (state, ctf) => state.ACL = ctf,
            SET_CENDE: (state, cende) => state.CENDE = cende,
            SET_CSBO: (state, csbo) => state.CSBO = csbo,
            SET_CSPA: (state, cspa) => state.CSPA = cspa,
            SET_SOL: (state, sol) => state.SOL = sol,
            SET_APV: (state, apv) => state.APV = apv,
            SELECTED_COLUMNS_MAIN_TBL: (state, columns) => state.SELECTED_COLUMNS_MAIN_TBL = columns,
            SET_CUR_JOB_TYPE: (state, tab) => state.CUR_JOB_TYPE = tab,
            SET_CUR_SETTINGS_TAB: (state, tab) => state.CUR_SETTINGS_TAB = tab,
            SET_LAST_UPDATE_ME: (state, lum) => state.LAST_UPDATE_ME = lum,
            SET_AUTO_REFRESH_LEADS: (state, flag) => state.AUTO_REFRESH_LEADS = flag,
            SET_ITG_SELECTED_FUNNELS_LIST: (state, list) => state.ITG_SELECTED_FUNNELS_LIST = list,

            // Filters
            SET_CUR_JOB_FILTERS: (state, fltjob) => state.CUR_JOB_FILTERS = fltjob,
            SET_CUR_SEARCH_FILTERS: (state, fltsrch) => state.CUR_SEARCH_FILTERS = fltsrch,
            SET_LOCK_SEARCH_FILTERS: (state, flag) => state.LOCK_SEARCH_FILTERS = flag,
        },
        actions: {
            login: ({commit, state}, newValue) => {
                // console.log('------------, newValue', JSON.stringify(newValue, null, 2))
                let now = Date.now();
                commit('SET_LOGGED_IN', true);
                commit('SET_AUTH_TOKEN', newValue.token);
                commit('SET_LOGGED_USER', newValue.user);
                commit('SET_ROLE_ID', newValue.role);
                commit('SET_CMU', newValue.can_manage_users);
                commit('SET_CSL', newValue.can_view_logs);
                commit('SET_CVD', newValue.can_view_deleted);
                commit('SET_CCL', newValue.can_copy_leads);
                commit('SET_CRL', newValue.can_reassign_leads);
                commit('SET_CETS', newValue.can_edit_traffic_source);
                commit('SET_CED', newValue.can_edit_details);
                commit('SET_CTF', newValue.can_toggle_fields);
                commit('SET_CCM', newValue.can_click_meta);
                commit('SET_CVS', newValue.can_view_settings);
                commit('SET_CCSC', newValue.can_click_show_comments);
                commit('SET_CFTD', newValue.can_toggle_ftd);
                commit('SET_CDEX', newValue.can_download_excel);
                commit('SET_ACL', newValue.allowed_columns_affiliate);
                commit('SET_CENDE', newValue.can_encrypt_decrypt);
                commit('SET_CSBO', newValue.can_see_brand_owner);
                commit('SET_CSPA', newValue.can_see_param_affiliate);
                commit('SET_SOL', newValue.show_only_own);
                commit('SET_APV', apv)
                commit('SET_LAST_UPDATE_ME', `${now}`)
                commit('SET_AUTO_REFRESH_LEADS', 1);
                localStorage.setItem('login', true);
                localStorage.setItem('user', JSON.stringify(newValue.user));
                localStorage.setItem('auth', newValue.token);
                // Cookies.set('cb', newValue.token, { expires: new Date(new Date().getTime() + 60 * 60 * 1000), path: '' })
                localStorage.setItem('role_id', newValue.user.role);
                localStorage.setItem('cmu', newValue.user.can_manage_users);
                localStorage.setItem('csl', newValue.user.can_view_logs);
                localStorage.setItem('cvd', newValue.user.can_view_deleted);
                localStorage.setItem('ccl', newValue.user.can_copy_leads);
                localStorage.setItem('crl', newValue.user.can_reassign_leads);
                localStorage.setItem('cets', newValue.user.can_edit_traffic_source);
                localStorage.setItem('ced', newValue.user.can_edit_details);
                localStorage.setItem('ctf', newValue.user.can_toggle_fields);
                localStorage.setItem('ccm', newValue.user.can_click_meta);
                localStorage.setItem('cvs', newValue.user.can_view_settings);
                localStorage.setItem('ccsc', newValue.user.can_click_show_comments);
                localStorage.setItem('cftd', newValue.user.can_toggle_ftd);
                localStorage.setItem('cdex', newValue.user.can_download_excel);
                localStorage.setItem('acl', newValue.user.allowed_columns_affiliate);
                localStorage.setItem('cende', newValue.user.can_encrypt_decrypt);
                localStorage.setItem('csbo', newValue.user.can_see_brand_owner);
                localStorage.setItem('cspa', newValue.user.can_see_param_affiliate);
                localStorage.setItem('sol', newValue.user.show_only_own);
                localStorage.setItem('apv', (require('../package.json')).version);
                localStorage.setItem('lum', `${now}`);
                localStorage.removeItem('cols');
                Cookies.set('arl', 1);
                return state.LOGGED_IN;
            },
            logout: ({commit, state}) => {
                commit('SET_LOGGED_IN', false);
                commit('SET_AUTH_TOKEN', null);
                commit('SET_LOGGED_USER', null);
                commit('SET_ROLE_ID', null);
                commit('SET_CMU', null);
                commit('SET_CSL', null);
                commit('SET_CVD', null);
                commit('SET_CCL', null);
                commit('SET_CRL', null);
                commit('SET_CETS', null);
                commit('SET_CED', null);
                commit('SET_CTF', null);
                commit('SET_CCM', null);
                commit('SET_CVS', null);
                commit('SET_CCSC', null);
                commit('SET_CFTD', null);
                commit('SET_ACL', null);
                commit('SET_CENDE', null);
                commit('SET_CSBO', null);
                commit('SET_CSPA', null);
                commit('SET_SOL', null);
                commit('SELECTED_COLUMNS_MAIN_TBL', null);
                commit('SET_CUR_JOB_TYPE', null);
                commit('SET_CUR_SETTINGS_TAB', null);
                commit('SET_CUR_INTEGRATION_TAB', null);
                commit('SET_APV', null);
                commit('SET_LAST_UPDATE_ME', null);
                commit('SET_CUR_SEARCH_FILTERS', null);
                commit('SET_CUR_JOB_FILTERS', null);
                commit('SET_LOCK_SEARCH_FILTERS', null)
                localStorage.removeItem('fltjob');
                localStorage.removeItem('fltsrch');
                localStorage.removeItem('login');
                localStorage.removeItem('auth');
                // Cookies.remove('auth')
                localStorage.removeItem('user');
                localStorage.removeItem('role_id');
                localStorage.removeItem('cmu');
                localStorage.removeItem('csl');
                localStorage.removeItem('ccl');
                localStorage.removeItem('crl');
                localStorage.removeItem('cets');
                localStorage.removeItem('ced');
                localStorage.removeItem('ctf');
                localStorage.removeItem('ccm');
                localStorage.removeItem('cvs');
                localStorage.removeItem('ccsc');
                localStorage.removeItem('cftd');
                localStorage.removeItem('cdex');
                localStorage.removeItem('acl');
                localStorage.removeItem('cende');
                localStorage.removeItem('csbo');
                localStorage.removeItem('cspa');
                localStorage.removeItem('sol');
                localStorage.removeItem('cvd');
                localStorage.removeItem('cols');
                localStorage.removeItem('apv');
                localStorage.removeItem('lum');
                Cookies.remove('cb');
                Cookies.remove('cbn');
                Cookies.remove('cit');
                Cookies.remove('cjty');
                Cookies.remove('cst');
                Cookies.remove('arl');
                Cookies.remove('lcksrchflt');
                return state.LOGGED_IN;
            },
            setJobsFilters({commit}, newValue) {
                localStorage.setItem('fltjob', JSON.stringify(newValue));
                commit('SET_CUR_JOB_FILTERS', newValue)
            },
            setSearchFilters({commit}, newValue) {
                localStorage.setItem('fltsrch', JSON.stringify(newValue));
                commit('SET_CUR_SEARCH_FILTERS', newValue)
            },
            setLckSearchFilters({commit}, flag) {
                Cookies.set('lcksrchflt', flag)
                commit('SET_LOCK_SEARCH_FILTERS', flag)
            },
            setCurSrvTime({commit}, newValue) {
                commit('SET_CUR_SRV_TIME', newValue.time);
                commit('SET_CUR_SRV_TIMEZONE', newValue.timezone);
            },
            removeJobsFilters({commit}) {
                localStorage.removeItem('fltjob');
                commit('SET_CUR_JOB_FILTERS', null);
            },
            removeSearchFilters({commit}) {
                localStorage.removeItem('fltsrch');
                commit('SET_CUR_SEARCH_FILTERS', null);
            },
            setAutoRefreshLeadFlag({commit}, newValue) {
                commit('SET_AUTO_REFRESH_LEADS', newValue);
                Cookies.set('arl', newValue);
            },
            setCurrentJobTypeTab({commit}, newValue) {
                commit('SET_CUR_JOB_TYPE', newValue);
                Cookies.set('cjty', newValue);
            },
            setCurrentSettingsTab({commit}, newValue) {
                commit('SET_CUR_SETTINGS_TAB', newValue);
                Cookies.set('cst', newValue);
            },
            setCurrentTab: ({commit}, newValue) => {
                commit('SET_CUR_INTEGRATION_TAB', newValue);
                Cookies.set('cit', newValue);
            },
            setCurrentBrand: ({commit}, newValue) => {
                commit('SET_CUR_BRAND', newValue);
                Cookies.set('cb', newValue.id);
                Cookies.set('cbn', newValue.name);
            },
            clearCurrentBrand: ({commit}) => {
                commit('SET_CUR_BRAND', {id: null, name: ''});
                Cookies.remove('cb');
                Cookies.remove('cbn');
            },
            setTableColumns({commit}, columns) {
                localStorage.setItem('cols', JSON.stringify(columns));
                commit('SELECTED_COLUMNS_MAIN_TBL', columns)
            },
            setToken({commit}, token) {
                localStorage.setItem('auth', token);
                commit('SET_AUTH_TOKEN', token);
            },
            setRole({commit}, role) {
                localStorage.setItem('role_id', role);
                commit('SET_ROLE_ID', null);
            },

            setItgSelFunLst({commit}, list) {
                commit('SET_ITG_SELECTED_FUNNELS_LIST', list);
            },

            // Changes
            setCmu({commit}, value) {
                localStorage.setItem('cmu', value);
                commit('SET_CMU', value);
            },
            setCsl({commit}, value) {
                localStorage.setItem('csl', value);
                commit('SET_CSL', value);
            },
            setCvd({commit}, value) {
                localStorage.setItem('cvd', value);
                commit('SET_CVD', value);
            },
            setCcl({commit}, value) {
                localStorage.setItem('ccl', value);
                commit('SET_CCL', value);
            },
            setCrl({commit}, value) {
                localStorage.setItem('crl', value);
                commit('SET_CRL', value);
            },
            setCets({commit}, value) {
                localStorage.setItem('cets', value);
                commit('SET_CETS', value);
            },
            setCed({commit}, value) {
                localStorage.setItem('ced', value);
                commit('SET_CED', value);
            },
            setCtf({commit}, value) {
                localStorage.setItem('ctf', value);
                commit('SET_CTF', value);
            },
            setCcm({commit}, value) {
                localStorage.setItem('ccm', value);
                commit('SET_CCM', value);
            },
            setCvs({commit}, value) {
                localStorage.setItem('cvs', value);
                commit('SET_CVS', value);
            },
            setCcsc({commit}, value) {
                localStorage.setItem('ccsc', value);
                commit('SET_CCSC', value);
            },
            setCftd({commit}, value) {
                localStorage.setItem('cftd', value);
                commit('SET_CFTD', value);
            },
            setCdex({commit}, value) {
                localStorage.setItem('cdex', value);
                commit('SET_CDEX', value);
            },
            setCende({commit}, value) {
                localStorage.setItem('cende', value);
                commit('SET_CENDE', value);
            },
            setCsbo({commit}, value) {
                localStorage.setItem('csbo', value);
                commit('SET_CSBO', value);
            },
            setCspa({commit}, value) {
                localStorage.setItem('cspa', value);
                commit('SET_CSPA', value);
            },
            setSol({commit}, value) {
                localStorage.setItem('sol', value);
                commit('SET_SOL', value);
            },
            setLum({commit}, time) {
                let now = `${Date.now()}`;
                localStorage.setItem('lum', time || now);
                commit('SET_LAST_UPDATE_ME', time || now);
            },

            setUsername({commit}, value) {
                let u = tryParseObject(localStorage.getItem('user'));
                u.username = value;
                localStorage.setItem('user', JSON.stringify(u));
                commit('SET_LOGGED_USER', u);
            },
            setFristName({commit}, value) {
                let u = tryParseObject(localStorage.getItem('user'));
                u.first_name = value;
                localStorage.setItem('user', JSON.stringify(u));
                commit('SET_LOGGED_USER', u);
            },
            setLastName({commit}, value) {
                let u = tryParseObject(localStorage.getItem('user'));
                u.last_name = value;
                localStorage.setItem('user', JSON.stringify(u));
                commit('SET_LOGGED_USER', u);
            },

        }
    })
}

function tryParseObject(str, fallback) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return typeof fallback !== 'undefined' ? fallback : str;
    }
}



function parseAcl(str) {
    if(str === 'all') return str;
    let arr = str.split(',');
    let hash = {};
    arr.forEach(e => hash[e] = true)
    return hash;
}

export default constructor;
