import { Notification } from "element-ui";

export default {
    install(Vue/*, options*/) {
        const httpClient = {
            sendHttpRequest(method, url, data, headers, errMsg = null){
                return new Promise((resolve, reject) => {
                    if(method === 'GET') {
                        let qs = '';
                        if (data) qs += '?' + Object.keys(data).map(key => key + '=' + data[key]).join('&');
                        Vue.http.get(url + qs, data)
                            .then(resp => {
                                if (resp.body.success && !!resp.body.data) resolve(resp.body.data);
                                else {
                                    errMsg = resp.body.msg;
                                    errMsg ? Notification.error({title: 'API Error', message: errMsg}) : Notification.error({title: 'API Error', message: resp.body.msg});
                                    return reject(resp.body.msg)
                                }
                            })
                            .catch(err => {
                                errMsg = `Status: ${err.status}, Error: ${err.body.msg}`;
                                if(err.status !== 401) errMsg ? Notification.error({title: 'API Error', message: errMsg}) : Notification.error({title: 'API Error', message: err.body.msg});
                                else {
                                    Notification.error({ title: 'API Error', message: 'Session ended. Please, login again' })
                                    reject(err);
                                }
                                return reject(err.body.msg)
                            })
                    } else if(method === 'DOWNLOAD') {
                        let qs = '';
                        if (data) qs += '?' + Object.keys(data).map(key => key + '=' + data[key]).join('&');
                        Vue.http.get(url + qs, data, {responseType: 'arraybuffer'})
                            .then(response => {
                                let blob = new Blob([response.blob()], {type: response.headers['content-type']}),
                                    filename = (response.headers['Content-Disposition'] || '').split('filename=')[1];
                                let result = document.createElement('a');
                                result.href = window.URL.createObjectURL(blob);
                                result.download = filename;
                                return result;
                            })
                    } else if(method === 'POST') {
                        Vue.http.post(url, data, headers )
                            .then(resp => {
                                if(resp.body.success && !!resp.body.data) resolve(resp.body.data);
                                else {
                                    errMsg = resp.body.msg;
                                    errMsg ? Notification.error({title: 'API Error', message: errMsg}) : Notification.error({title: 'API Error', message: resp.body.msg});
                                    return reject(resp.msg)
                                }
                            })
                            .catch(err => {
                                errMsg = `Status: ${err.status}, Error: ${err.body.msg}`;
                                if(err.status !== 401) errMsg ? Notification.error({title: 'API Error', message: errMsg}) : Notification.error({title: 'API Error', message: err.body.msg});
                                else {
                                    Notification.error({ title: 'API Error', message: 'Session ended. Please, login again' })
                                    reject(err);
                                }
                                return reject(err.body.msg)
                            })
                    } else if(method === 'PUT') {
                        Vue.http.put(url, data, headers )
                            .then(resp => {
                                if(resp.body.success && !!resp.body.data) resolve(resp.body.data);
                                else {
                                    errMsg = resp.body.msg;
                                    errMsg ? Notification.error({title: 'API Error', message: errMsg}) : Notification.error({title: 'API Error', message: resp.body.msg});
                                    return reject(resp.body.msg)
                                }
                            })
                            .catch(err => {
                                errMsg = `Status: ${err.status}, Error: ${err.body.msg}`;
                                errMsg ? Notification.error({title: 'API Error', message: errMsg}) : Notification.error({title: 'API Error', message: err.body.msg});
                                if(err.status === 401) reject(err);
                                return reject(err.body.msg)
                            })
                    } else if(method === 'DELETE') {
                        let qs = '';
                        if(data) qs += '?' +  Object.keys(data).map(key => key + '=' + data[key]).join('&');
                        Vue.http.delete(url + qs)
                            .then(resp => {
                                if (resp.body.success && !!resp.body.data) resolve(resp.body.data);
                                else {
                                    errMsg = resp.body.msg;
                                    errMsg ? Notification.error({title: 'API Error', message: errMsg}) : Notification.error({title: 'API Error', message: resp.body.msg});
                                    return reject(resp.body.msg)
                                }
                            })
                            .catch(err => {
                                errMsg = `Status: ${err.status}, Error: ${err.body.msg}`;
                                if(err.status !== 401) errMsg ? Notification.error({title: 'API Error', message: errMsg}) : Notification.error({title: 'API Error', message: err.body.msg});
                                else {
                                    Notification.error({ title: 'API Error', message: 'Session ended. Please, login again' })
                                    reject(err);
                                }
                                return reject(err.body.msg)
                            })
                    } else {
                        console.error('[ERROR] HTTP CLIENT - Method not supported')
                    }
                })
            }
        };

        Vue.apix = httpClient;
        Vue.prototype.$apix = httpClient;
    }
}
