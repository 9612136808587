<template lang="pug">
	#app
		Layout
		transition(name="slide-fade")
			router-view
		vue-progress-bar
</template>

<style>
	body {

		background-image: url('~@/assets/bg.jpg');
		/*opacity: 0.6;*/
	}

	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;

		/*margin-top: 60px;*/
	}
</style>

<script>
	// import HelloWorld from './components/HelloWorld.vue'
	import Layout from './components/layout';
	import Vue from 'vue';

	export default {
		name: 'app',
		components: {
			Layout
		},
		beforeCreate() {
			(async () => {
				let token = localStorage.getItem('auth');
				Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
				if (!token) {
					await this.$router.push('login');
				} else {
					this.$apix.sendHttpRequest('POST', 'auth/verify', {token})
						.then(res => {
							if (!!res.user) {
								// Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
							} else {
								// console.error('[AUTH ERROR] Not authenticated');
								this.$store.dispatch('logout');
								this.$router.push({name: 'login'});
							}
						})
						.catch(err => {
							// console.error('[AUTH ERROR] Not authenticated, ', err);
							this.$store.dispatch('logout');
							this.$router.push('login');
						})
				}
			})()
		},
		created() {
			this.$Progress.start();
			this.$router.beforeEach((to, from, next) => {
				if (to.meta.progress !== undefined) {
					let meta = to.meta.progress
					// parse meta tags
					this.$Progress.parseMeta(meta);
				}
				this.$Progress.start()
				//  continue to next page
				next()
			});
			this.$router.afterEach(() => {
				//  finish the progress bar
				this.$Progress.finish()
			})
		},
		mounted() {
			setTimeout(() => this.hc(), 1000);
			setInterval(() => this.hc(), 10000)
		},
		data() {
			return {
				newVersionPopupShown: false
			}
		},
		computed: {
			appVer(){
				return this.$store.getters.PACKAGE_VERSION
			},
			currentAppVer() {
				return this.$store.getters.ORIGINAL_PACKAGE_VERSION
			},
			lastUpdateMe() {
				return this.$store.getters.LAST_UPDATE_ME
			},
			lgoKey() {
				return process.env.VUE_APP_LGO_KEY;
			}
		},
		methods: {
			hc() {
				if(this.$store.getters.LOGGED_IN) this.$apix.sendHttpRequest('POST', 'user/hc', { apv:this.appVer, tsu: +this.lastUpdateMe })
						.then(resp => {
							// console.log(resp);
							// console.log(resp.changes);
							// console.log('----===----');
							this.$store.dispatch('setCurSrvTime',resp);
							if(resp.changes) this.parseChanges(resp).then();
							if(resp.code || this.currentAppVer && this.appVer !== this.currentAppVer) this.parseRespnse(resp);
							if(resp.token) {
								this.$store.dispatch('setToken', `${resp.token}`)
								Vue.http.headers.common['Authorization'] = 'Bearer ' + resp.token;
							}
							if(resp.rid) this.$store.dispatch('setRole', resp.rid);
						})
				.catch(err => {
					console.error('[APP ERROR]', err)
					if(err.status === 401) this.$store.dispatch('logout').then(() => window.location.href = '/');
				})
			},
			parseRespnse(response) {
				if(this.appVer !== this.currentAppVer) {
					if(!this.newVersionPopupShown) {
						this.newVersionPopupShown = true;
						this.$confirm('New client version is released! Please, logout and log in again', 'Client App Notify').then(() => this.$store.dispatch('logout').then(() => this.$router.push({name: 'login'}))).catch(() => this.newVersionPopupShown = true)
					}
				}
				switch(response.code) {
					case 1000:
						if(!this.newVersionPopupShown) {
							this.newVersionPopupShown = true;
							this.$confirm(response.msg, 'API Notify').then(() => this.$store.dispatch('logout').then(() => this.$router.push({name: 'login'}))).catch(() => this.newVersionPopupShown = true)
						}
						break;
				}
			},
			async parseChanges(response) {

				try {
					let refresh = false;
					if (response.changes) this.$store.dispatch('setLum', response.updateTime);
					if (typeof response.changes.can_manage_users !== 'undefined') {
						// console.log('in:can_manage_users')
						await this.$store.dispatch('setCmu', `${response.changes.can_manage_users}`);
						// console.log('out:can_manage_users',)
					}
					if (typeof response.changes.can_view_logs !== 'undefined') {
						// console.log('in:can_view_logs')
						await this.$store.dispatch('setCvl', `${response.changes.can_view_logs}`);
						// console.log('out:can_view_logs',)
					}
					if (typeof response.changes.can_view_deleted !== 'undefined') {
						// console.log('in:can_view_deleted')
						await this.$store.dispatch('setCvd', `${response.changes.can_view_deleted}`);
						refresh = true;
						// console.log('out:can_view_deleted',)
					}
					if (typeof response.changes.can_copy_leads !== 'undefined') {
						// console.log('in:can_copy_leads')
						await this.$store.dispatch('setCcl', `${response.changes.can_copy_leads}`);
						refresh = true;
						// console.log('out:can_copy_leads',)
					}
					if (typeof response.changes.can_edit_details !== 'undefined') {
						// console.log('in:can_copy_leads')
						await this.$store.dispatch('setCed', `${response.changes.can_edit_details}`);
						refresh = true;
						// console.log('out:can_copy_leads',)
					}
					if (typeof response.changes.can_reassign_leads !== 'undefined') {
						// console.log('in:can_reassign_leads')
						await this.$store.dispatch('setCrl', `${response.changes.can_reassign_leads}`);
						refresh = true;
						// console.log('out:can_reassign_leads',)
					}
					if (typeof response.changes.can_edit_traffic_source !== 'undefined') {
						// console.log('in:can_reassign_leads')
						await this.$store.dispatch('setCets', `${response.changes.can_edit_traffic_source}`);
						refresh = true;
						// console.log('out:can_reassign_leads',)
					}
					if (typeof response.changes.can_toggle_fields !== 'undefined') {
						// console.log('in:can_reassign_leads')
						await this.$store.dispatch('setCtf', `${response.changes.can_toggle_fields}`);
						refresh = true;
						// console.log('out:can_reassign_leads',)
					}
					if (typeof response.changes.can_click_meta !== 'undefined') {
						// console.log('in:can_reassign_leads')
						await this.$store.dispatch('setCcm', `${response.changes.can_click_meta}`);
						refresh = true;
						// console.log('out:can_reassign_leads',)
					}
					if (typeof response.changes.can_click_show_comments !== 'undefined') {
						// console.log('in:can_reassign_leads')
						await this.$store.dispatch('setCcsc', `${response.changes.can_click_show_comments}`);
						refresh = true;
						// console.log('out:can_reassign_leads',)
					}
					if (typeof response.changes.can_toggle_ftd !== 'undefined') {
						// console.log('in:can_reassign_leads')
						await this.$store.dispatch('setCftd', `${response.changes.can_toggle_ftd}`);
						refresh = true;
						// console.log('out:can_reassign_leads',)
					}
					if (typeof response.changes.can_view_settings !== 'undefined') {
						// console.log('in:can_reassign_leads')
						await this.$store.dispatch('setCvs', `${response.changes.can_view_settings}`);
						refresh = true;
						// console.log('out:can_reassign_leads',)
					}
					if (typeof response.changes.can_download_excel !== 'undefined') {
						// console.log('in:can_reassign_leads')
						await this.$store.dispatch('setCdex', `${response.changes.can_download_excel}`);
						refresh = true;
						// console.log('out:can_reassign_leads',)
					}
					if (typeof response.changes.can_see_brand_owner !== 'undefined') {
						// console.log('in:can_see_brand_owner')
						await this.$store.dispatch('setCsbo', `${response.changes.can_see_brand_owner}`);
						refresh = true;
						// console.log('out:can_see_brand_owner',)
					}
					if (typeof response.changes.can_see_param_affiliate !== 'undefined') {
						// console.log('in:can_see_param_affiliate')
						await this.$store.dispatch('setCspa', `${response.changes.can_see_param_affiliate}`);
						refresh = true;
						// console.log('out:can_see_param_affiliate',)
					}
					if (typeof response.changes.show_only_own !== 'undefined') {
						// console.log('in:can_see_brand_owner')
						await this.$store.dispatch('setSol', `${response.changes.show_only_own}`);
						refresh = true;
						// console.log('out:can_see_brand_owner',)
					}
					if (typeof response.changes.username !== 'undefined') {
						// console.log('in:username')
						await this.$store.dispatch('setUsername', `${response.changes.username}`);
						// console.log('out:username',)
					}
					if (typeof response.changes.first_name !== 'undefined') {
						// console.log('in:first_name')
						await this.$store.dispatch('setFristName', `${response.changes.first_name}`);
						// console.log('out:first_name',)
					}
					if (typeof response.changes.last_name !== 'undefined') {
						// console.log('in:last_name')
						await this.$store.dispatch('setLastName', `${response.changes.last_name}`);
						// console.log('out:last_name',)
					}
					// @todo - finish the kicking out procedure
					if(response.changes._ === this.lgoKey) {
						await this.$store.dispatch('logout');
						refresh = true;
					}

					// todo - add allowed_columns support
				if(refresh) setTimeout(() => window.location.reload(), 3000);
				} catch (e) {
					console.error(e);
				}
			}
		}
	}
</script>


